import { Injectable } from '@angular/core';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

import { CryptoService } from '../_services/crypto.service';
import { merge } from 'rxjs/operators';

@Injectable()


export class JwtInterceptor implements HttpInterceptor {

    helper = new JwtHelperService();
    _cryptoService: CryptoService = new CryptoService();

    constructor(
        private _authService: AuthService,
        private cookieService: CookieService
    ) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const savedtoken = this._cryptoService.decryptText(this.cookieService.get(environment.keys.accessToken));

        if (req.headers.has(environment.InterceptorSkipHeader)) {

            // console.log('bypassing jwt interceptor...');

            const r = req.clone({
                headers: req.headers.delete(environment.InterceptorSkipHeader)
            });

            return next.handle(r);

        } else if (savedtoken) {
            // console.log('we have a saved token');

            let token = savedtoken;

            const decodedToken = this.helper.decodeToken(token);
            const expirationDate = this.helper.getTokenExpirationDate(token);
            const isExpired = this.helper.isTokenExpired(token);

            if (!isExpired) {

                //   console.log('token has not expired');
                const cloned = this.setHeader(req, token); // set headers and clone request
                return next.handle(cloned); // send request with new header

            } else {
                //  console.log('token has EXPIRED');
                const username = this._cryptoService.decryptText(this.cookieService.get(environment.keys.username));
                const password = this._cryptoService.decryptText(this.cookieService.get(environment.keys.password));

                if (username && password) {


                    const creds = {
                        username: username,
                        password: password,
                    };

                    return this._authService.refreshToken(creds)
                        .pipe(merge((data: any) => {

                            token = data.id_token; // send request with new header
                            // tslint:disable-next-line:max-line-length

                            //   console.log('new token', token);

                            const exp = this.helper.getTokenExpirationDate(token);

                            //  console.log('new token expires', exp);

                            const cookieExp = new Date(exp);
                            cookieExp.setDate(exp.getDate() + 30);
                            //   console.log('cookie expires', cookieExp);


                            this.cookieService.set(environment.keys.accessToken, this._cryptoService.encryptText(token).toString(), cookieExp, '/');  // update token in storage
                            this.cookieService.set(environment.keys.username, this._cryptoService.encryptText(creds.username).toString(), cookieExp, '/'); // update username expiry
                            this.cookieService.set(environment.keys.password, this._cryptoService.encryptText(creds.password).toString(), cookieExp, '/'); // update password expiry

                            const cloned = this.setHeader(req, token); // set headers and clone request
                            return next.handle(cloned); // send request with new header
                        }));



                } else {

                    //     console.log('no username or password - sending request unchanged');
                    return next.handle(req); // send request unchanged

                }

            }

        } else {

            //  console.log('no saved token - sending request unchanged');
            return next.handle(req); // send request unchanged
        }



    }


    public setHeader(req, token) {
        return req.clone({
            headers: req.headers.set('Authorization',
                'Bearer ' + token)
        });
    }



}
