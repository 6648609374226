import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Globals } from '../_globals';
import { CryptoService } from '../_services/crypto.service';
import { Events } from '@ionic/angular';

@Injectable()
export class AuthService {

    helper = new JwtHelperService();
    cryptoService: CryptoService = new CryptoService();

    constructor(
        private http: HttpClient,
        private router: Router,
        public globals: Globals,
        public events: Events,
        private cookieService: CookieService
    ) { }

    public login(creds) {

        const headers = new HttpHeaders().set(environment.InterceptorSkipHeader, '');

        return new Observable((observer) => {

            this.http.post(environment.apiUrl + 'api/authenticate', creds, { headers })
                .pipe((res: any) => {
                    res.subscribe(data => {

                        // tslint:disable-next-line:max-line-length
                        const expirationDate = this.helper.getTokenExpirationDate(data.id_token);

                        const cookieExp = new Date(expirationDate);
                        cookieExp.setDate(expirationDate.getDate() + 30);

                        // tslint:disable: max-line-length
                        this.cookieService.set(environment.keys.accessToken, this.cryptoService.encryptText(data.id_token).toString(), cookieExp, '/');
                        this.cookieService.set(environment.keys.username, this.cryptoService.encryptText(creds.username).toString(), cookieExp, '/');
                        this.cookieService.set(environment.keys.password, this.cryptoService.encryptText(creds.password).toString(), cookieExp, '/');

                        console.log('cookies set');
                        observer.next();

                    },
                        error => {
                            this.events.publish('login:error', error);
                        });

                    return res;
                })
                .pipe(catchError(this.handleError));

        });
    }

    public storeGlobalUserAndOrg(user) {

        return new Observable(observer => {

            this.globals.user = {
                id: user.id,
                authorities: user.authorities,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                langKey: user.langKey,
                login: user.login
            };

            this.globals.store = {
                id: user.consoleUser.store.id,
                name: user.consoleUser.store.name,
                currency: user.consoleUser.store.currency
            };

            if (user.authorities.find(auth => auth.name === 'ROLE_ADMIN')) {

                this.globals.user.role = 1;
                this.globals.user.routeRole = 'console';

            } else if (user.authorities.find(auth => auth.name === 'ROLE_CONSOLE')) {

                this.globals.user.role = 1;
                this.globals.user.routeRole = 'console';
            } else {
                this.globals.user.role = 0;
            }

            return observer.next(this.globals);

        });

    }

    public logout() {

        console.log('lets logout');
        this.cookieService.deleteAll('/');
        this.router.navigate(['/']);
    }

    public refreshToken(creds) {
        const headers = new HttpHeaders().set(environment.InterceptorSkipHeader, '');
        return this.http.post(environment.apiUrl + 'api/authenticate', creds, { headers });
    }

    public isLoggedIn() {
        const loggedin = this.cookieService.check(environment.keys.accessToken);
        return loggedin;
    }

    public getUser() {
        return this.http.get(environment.apiUrl + 'api/ext-bludot-user/current')
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // HANDLE DATA & ERRORS FUNCTIONS

    private handleData(res: HttpResponse<any>) {
        const body = res;
        return body;
    }

    private handleError(error: any) {
        console.log('sds');
        return Observable.throw(
            new HttpErrorResponse(error)
        );
    }
}
