import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class StoreService {

    constructor(
        private http: HttpClient
    ) { }

    // Get all Stores
    public getAllStores(pagination, sort) {

        const sortString = '&sort=' + sort;

        let paginationString = '';
        paginationString += '?size=' + pagination.size;
        paginationString += '&page=' + pagination.page;

        return this.http.get(environment.apiUrl + 'api/ext-store' + paginationString + sortString, { observe: 'response' })
            .pipe(map(this.handleDataWithHeaders))
            .pipe(catchError(this.handleError));
    }

    // Get all stores with no pagination (for lists and dropdowns)
    public getAllStoresNoPagination() {
        let paginationString = '';
        paginationString += '?size=' + 10000;
        paginationString += '&page=' + 0;
        return this.http.get(environment.apiUrl + 'api/ext-store' + paginationString)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // Get Store by Store Id
    public getStoreById(id) {
        return this.http.get(environment.apiUrl + 'api/ext-store/' + id)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // Create Store
    public createStore(obj) {
        const body = {
            address1: obj.address1,
            address2: obj.address2,
            city: obj.city,
            currency: obj.currency,
            enabled: true,
            latitude: null,
            longitude: null,
            name: obj.name,
            postcode: obj.postcode
        }

        return this.http.post(environment.apiUrl + 'api/ext-store', body)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // Edit Store
    public editStore(id, obj) {

        const body = {
            // tslint:disable-next-line:object-literal-shorthand
            id: id,
            address1: obj.address1,
            address2: obj.address2,
            city: obj.city,
            currency: obj.currency,
            enabled: obj.enabled,
            name: obj.name,
            postcode: obj.postcode
        };

        return this.http.put(environment.apiUrl + 'api/ext-store', body)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));

    }

    // Get All Deleted Stores
    public getAllDeletedStores(pagination, sort) {

        const sortString = '&sort=' + sort;

        let paginationString = '';
        paginationString += '?size=' + pagination.size;
        paginationString += '&page=' + pagination.page;

        return this.http.get(environment.apiUrl + 'api/ext-store/deleted' + paginationString + sortString, { observe: 'response' })
            .pipe(map(this.handleDataWithHeaders))
            .pipe(catchError(this.handleError));
    }

    // Get Deleted Store by Store Id
    public getDeletedStoreById(id) {
        return this.http.get(environment.apiUrl + 'api/ext-store/deleted/' + id)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // Set Store App
    public setStoreApp(storeid, appid) {
        const body = {
            appId: appid,
            storeId: storeid
        };
        return this.http.post(environment.apiUrl + 'api/ext-store/set-app', body)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // Set Store Group
    public setStoreGroup(storeid, groupuserid) {
        const body = {
            groupUserId: groupuserid,
            storeId: storeid
        };
        return this.http.post(environment.apiUrl + 'api/ext-store/set-group-user', body)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // Set Store Owner
    public setStoreOwner(storeid, owneruserid) {
        const body = {
            storeOwnerUserId: owneruserid,
            storeId: storeid
        };
        return this.http.post(environment.apiUrl + 'api/ext-store/set-store-owner-user', body)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // Delete Store
    public deleteStore(id) {

    }

    // Edit location
    public updateStoreLocation(obj) {
        return this.http.put(environment.apiUrl + 'api/ext-store', obj)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // STORE CONFIG

    public createStoreConfig(obj) {
        return this.http.post(environment.apiUrl + 'api/ext-store-config', obj)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    public editStoreConfig(obj) {
        return this.http.put(environment.apiUrl + 'api/ext-store-config', obj)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // PUSH NOTIFICATIONS
    public sendNotification(obj) {
        return this.http.post(environment.apiUrl + 'api/ext-store/send-push-notification', obj)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // HANDLE DATA
    private handleData(res: HttpResponse<any>) {
        const body = res;
        return body;
    }

    public handleDataWithHeaders(res: HttpResponse<any>) {

        const h = res.headers;
        const b = res.body;
        const data = {
            headers: h,
            body: b
        };
        return data;
    }

    private handleError(error: any) {
        return Observable.throw(
            new HttpErrorResponse(error)
        );
    }

}
