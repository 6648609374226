import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoService {

    encryptText(message: any) {
        const ciphertext = CryptoJS.AES.encrypt(message, environment.secret).toString();
        return ciphertext;
    }

    decryptText(ciphertext: any) {
        const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), environment.secret);
        const plaintext = bytes.toString(CryptoJS.enc.Utf8);
        return plaintext;
    }

    encryptObject(data: any) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), environment.secret).toString();
        return ciphertext;
    }

    decryptObject(ciphertext: any) {
        const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), environment.secret);
        const plaintext = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return plaintext;
    }



}
