import { Component } from '@angular/core';

import { Platform, Events, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { WebSocketProvider } from './_services/websocket';
import { PrintService } from './_services/print.service';
import { environment } from '../environments/environment';
import { Globals } from './_globals';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  prod = false;
  storeid;
  timer;
  interval;

  stompClient;

  wsLoading;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public websocketProvider: WebSocketProvider,
    public printService: PrintService,
    public events: Events,
    public globals: Globals,
    public loadingController: LoadingController
  ) {
    this.prod = environment.production;

    // Websocket event subscription
    events.subscribe('websocket:connect', (storeid) => {
      this.storeid = storeid;

      console.log('present ws loading...');
      this.presentWSLoading();

      setTimeout(() => {
        console.log('wait 500ms and lets connect...');
        this.wsConnect();
        this.refreshApplication();
      }, 500);
    });
    this.initializeApp();
  }

  wsConnect() {
    this.stompClient = this.websocketProvider.connect();
    this.stompClient.connect(
      {},
      (frame) => {
        console.log('***WEB SOCKET CONNECTED***');
        this.globals.websocket = true;
        clearTimeout(this.timer);

        if (this.wsLoading) {
          this.wsLoading.dismiss();
        }

        this.stompClient.subscribe(
          '/topic/purchase/' + this.storeid,
          (response) => {
            const rsp = JSON.parse(response.body);

            setTimeout(() => {
              console.log('NEW ORDER - ', rsp);

              this.printService.printTicket(rsp, true);

              if (rsp.purchaseType === 'IN_STORE') {
                this.events.publish('purchases-store:updated');
              } else if (rsp.purchaseType === 'GROUP') {
                this.events.publish('purchases-group:updated');
              } else if (rsp.purchaseType === 'DELIVERY') {
                this.events.publish('purchases-delivery:updated');
              } else if (rsp.purchaseType === 'KIOSK') {
                this.events.publish('purchases-kiosk:updated');
              } else {
                console.log('invalid purchase type');
              }
            }, 1000 * 30);
          },
          { id: 'purchase-subscription' }
        );
      },
      this.wsErrorCallback.bind(this)
    );
  }

  wsErrorCallback(): void {
    this.globals.websocket = false;
    console.log('error callback called, socket not connected!');
    // reconnecting
    this.wsConnect();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.connectPrinter();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  connectPrinter() {
    this.printService.connectPrinter();
  }

  async presentWSLoading() {
    this.wsLoading = await this.loadingController.create({
      message: 'Connecting to live orders...',
    });

    await this.wsLoading.present();

    this.timer = setTimeout(() => {
      console.log('refresh page...');
      this.stompClient.unsubscribe('purchase-subscription');
      location.reload(true);
    }, 10000);

    const { role, data } = await this.wsLoading.onDidDismiss();

    console.log('Loading dismissed!');
  }

  refreshApplication() {
    this.interval = setInterval(() => {
      console.log('refreshing the application...');
      this.stompClient.unsubscribe('purchase-subscription');
      location.reload(true);
    }, 1000 * 60 * 10);
  }
}
