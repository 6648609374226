import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Globals } from '../_globals';
import { Observable } from 'rxjs';
import { Events } from '@ionic/angular';

@Injectable()
export class ConsoleResolver implements Resolve<any> {

    role = 1;

    constructor(
        public authService: AuthService,
        public globals: Globals,
        private router: Router,
        public events: Events
    ) { }

    resolve() {
        return new Observable(observer => {

            this.authService.getUser().subscribe((user: any) => {
                this.authService.storeGlobalUserAndOrg(user).subscribe((res: any) => {

                    if (!this.checkRole(res.user.role)) {
                        this.router.navigate(['/restricted']);
                    }

                    console.log('** RESOLVER websocket:connect');
                    this.events.publish('websocket:connect', (this.globals.store.id));

                    return observer.complete();
                });

            });

        });

    }

    public checkRole(role) {

        if (role !== this.role) {
            this.authService.logout();
            return false;
        } else {
            return true;
        }

    }
}
