import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
    printer: any = {
        controllerStatus: 'Not Running',
        printerStatus: 'Not Conneted'
    };
    websocket = false;
    secret: 'basic';
    user: any = {
        id: null,
        authorities: [],
        dateOfBirth: '',
        email: '',
        firstName: '',
        lastName: '',
        langKey: 'en',
        login: '',
        role: 0,
        routeRole: '',
        organisation: '',
        orderType: 'STORE'
    };
    // HARDCODED
    store: any = {
        id: 0,
        name: '',
        currency: ''
    };

    daysOfWeek: any = [
        { name: 'Sun', value: 0 },
        { name: 'Mon', value: 1 },
        { name: 'Tue', value: 2 },
        { name: 'Wed', value: 3 },
        { name: 'Thu', value: 4 },
        { name: 'Fri', value: 5 },
        { name: 'Sat', value: 6 }
    ];

}
