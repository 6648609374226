import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PurchaseService {
  constructor(private http: HttpClient) {}

  public getWarehouseInfoForPurchase(purchase) {
    const headers = new HttpHeaders().set('app-key', '4c8b3bc3-1e43-4cb7-8918-44e0f754b3a1');

    return this.http
      .post('https://toolkit-api.bludot.cloud/v1/' + 'public/items/by-ean/', purchase, {
        headers,
        observe: 'response',
      })
      .pipe(map(this.handleDataWithHeaders))
      .pipe(catchError(this.handleError));
  }
  // Get All Purchases By Store
  public getAllPurchasesByStore(id) {
    let paginationString = '';
    // paginationString += '?size=' + pagination.size;
    // paginationString += '&page=' + pagination.page;
    paginationString += '?size=' + '10000';
    paginationString += '&page=' + '0';
    return this.http
      .get(environment.apiUrl + 'api/ext-purchase/by-store/' + id + paginationString, {
        observe: 'response',
      })
      .pipe(map(this.handleDataWithHeaders))
      .pipe(catchError(this.handleError));
  }

  // Get Single Purchase
  public getSinglePurchase(id) {
    return this.http
      .get(environment.apiUrl + 'api/ext-purchase/' + id, { observe: 'response' })
      .pipe(map(this.handleDataWithHeaders))
      .pipe(catchError(this.handleError));
  }

  public getAllRelevantPurchasesByStore(id) {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    // console.log('startDate', startDate);

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    // console.log('endDate', endDate);

    const startMilli = startDate.getTime();
    const endMilli = endDate.getTime();

    let paginationString = '';
    paginationString += '?size=' + '10000';
    paginationString += '&page=' + '0';
    const queryString = '&endDate=' + endMilli + '&startDate=' + startMilli;
    // tslint:disable-next-line: max-line-length
    return this.http
      .get(
        environment.apiUrl +
          'api/ext-purchase/between-dates/by-store/' +
          id +
          paginationString +
          queryString,
        { observe: 'response' }
      )
      .pipe(map(this.handleDataWithHeadersAndSortPurchaseItems))
      .pipe(catchError(this.handleError));
  }

  public updatePurchaseStatus(obj) {
    return this.http
      .post(environment.apiUrl + 'api/ext-purchase/set-purchase-status', obj)
      .pipe(map(this.handleData))
      .pipe(catchError(this.handleError));
  }

  // HANDLE DATA
  private handleData(res: HttpResponse<any>) {
    const body = res;
    return body;
  }

  public handleDataWithHeadersAndSortPurchaseItems(res: HttpResponse<any>) {
    const hd = res.headers;
    const bd = res.body;

    bd.forEach((purchase, key) => {
      // console.log('order', purchase.id);
      purchase.purchaseItems.sort((a, b) => {
        return a.id - b.id;
      });
    });

    // console.log('handleDataWithHeadersAndSortPurchaseItems', bd);

    const data = {
      headers: hd,
      body: bd,
    };
    return data;
  }

  public handleDataWithHeaders(res: HttpResponse<any>) {
    const h = res.headers;
    const b = res.body;
    const data = {
      headers: h,
      body: b,
    };
    return data;
  }

  private handleError(error: any) {
    return Observable.throw(new HttpErrorResponse(error));
  }
}
