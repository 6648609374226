import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../_globals';
import { formatDate, formatCurrency, getCurrencySymbol } from '@angular/common';
import { PurchaseService } from '../_services/purchase.service';
import { BuilderGroupPipe } from '../_pipes/builder-group.pipe';

import * as qz from '../../assets/print/qz-tray';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  newWindow: any;
  printer: any;

  constructor(
    private router: Router,
    public globals: Globals,
    public builderGroup: BuilderGroupPipe,
    public purchaseService: PurchaseService
  ) {}

  printTicket(p, autoPrint = false) {
    // console.log('printing ticket', p);

    if (autoPrint && p.purcahaseStatus === 'CANCELLED') {
      console.log('dont print cancelled order automatically');
    } else {
      this.purchaseService.getSinglePurchase(p.id).subscribe((data: any) => {
        const originalPurchase = data.body;

        // console.log('printTicket originalPurchase', originalPurchase);

        // sort purchace items by id
        originalPurchase.purchaseItems.sort((a, b) => {
          return a.id - b.id;
        });

        this.purchaseService
          .getWarehouseInfoForPurchase(originalPurchase)
          .subscribe((warehousePurchase: any) => {
            const purchase = warehousePurchase.body;
            // console.log('printTicket warehousePurchase', warehousePurchase);

            let charge = 'PAYMENT DUE';

            if (purchase.charge) {
              charge = purchase.charge.chargeStatus + ' (' + purchase.charge.transactionId + ')';
            }

            const orderTimeAdjusted = new Date(purchase.orderTime);

            const config = qz.configs.create(this.printer);

            const purchaseItems = [];

            // console.log('purchase.purchaseItems', purchase.purchaseItems);

            // grouping items
            const groupedItems = purchase.purchaseItems.reduce((obj, item) => {
              let group = 'z_No Group';

              // Get the group
              if (item.bdotItem && item.bdotItem.comm_group) {
                group = item.bdotItem.comm_group;
              }

              // If the group doesn't already exist as a key in the object, create it
              if (!obj.hasOwnProperty(group)) {
                obj[group] = [];
              }

              // Push the item to its  key
              obj[group].push(item);

              // Pass the object on to the next loop
              return obj;
            }, {});
            // of grouping items

            // console.log('groupedItems', groupedItems);

            const groupEntries = Object.entries(groupedItems);
            // console.log('groupEntries', groupEntries);

            groupEntries.sort();

            // console.log('groupEntries2', groupEntries);

            for (const groupEntry of groupEntries) {
              //  console.log('groupEntry', groupEntry);
              const groupTitle = `***** ${groupEntry[0]} *****\x0A`;
              const groupItems: any = groupEntry[1];

              purchaseItems.push(groupTitle);

              for (const item of groupItems) {
                // tslint:disable-next-line:max-line-length
                // console.log('item', item);

                let ean = '';
                if (item.ean) {
                  ean = '(' + item.ean + ')';
                }

                let receiptName = item.displayName;

                if (item.warehouse) {
                  if (item.warehouse.brandbank_information) {
                    receiptName = item.warehouse.brandbank_information.description;
                  }
                }

                let thisItem = `* ${receiptName} ${ean} ${formatCurrency(
                  item.price,
                  'en',
                  purchase.store.currency
                )} (qty ${item.quantity})\x0A`;

                // for (const component of item.purchaseItemComponents) {
                //     const thisComponent = `- [${component.grouping}] ${component.name}\x0A`;
                //     thisItem += thisComponent;
                // }

                const groupedPurchaseItemComponents = this.builderGroup.transform(
                  item.purchaseItemComponents
                );

                for (const grouping of groupedPurchaseItemComponents) {
                  let thisGroup = `- [${grouping.group}]\x0A`;

                  for (const component of grouping.items) {
                    const thisComponent = `- ${component.name}\x0A`;
                    thisGroup += thisComponent;
                  }

                  thisItem += thisGroup;
                }

                purchaseItems.push(thisItem);
              }
            }

            // qz.io ESC/POS 2.0 raw printing

            let printData = [
              '\x1B' + '\x40', // init
              // '\x1B' + '\x45', // bold on
              // '\x1D' + '\x21' + '\x11', // double font size
              ' #' + purchase.id + '\x0A',
              // '\x1D' + '\x21' + '\x00', // standard font size
              // '\x1B' + '\x45', // bold off
              purchase.customerUser.firstName + ' ' + purchase.customerUser.lastName + '\x0A',
              purchase.customerUser.customerUser.phone + '\x0A',
              charge + '\x0A',
              '\x0A', // line break
              '----------------------------------------' + '\x0A',
              'TOTAL: ' +
                formatCurrency(purchase.totalPrice, 'en', purchase.store.currency) +
                '\x0A',
              'TYPE: ' + purchase.purchaseType + '\x0A',
              'ORDERED: ' + formatDate(orderTimeAdjusted, 'E d MMM yy - HH:mm', 'en') + '\x0A',
              'DUE: ' + formatDate(purchase.due, 'E d MMM yy - HH:mm', 'en', 'UTC') + '\x0A',
              '----------------------------------------' + '\x0A',
              '\x0A', // line break
            ];

            // add group info
            if (purchase.purchaseType === 'GROUP') {
              printData = printData.concat([
                // tslint:disable-next-line:max-line-length
                'GROUP: ' +
                  purchase.workplaceSlot.workplace.name +
                  ' (' +
                  purchase.workplaceSlot.workplace.telephone +
                  ')' +
                  '\x0A',
                'SLOT: ' + purchase.workplaceSlot.name + '\x0A',
                '----------------------------------------' + '\x0A',
                '\x0A', // line break
              ]);
            }

            //  console.log('purchaseItems', purchaseItems);

            // add order data
            printData = printData.concat(purchaseItems);

            // add notes
            if (purchase.note) {
              printData = printData.concat([
                '\x0A', // line break
                '----------------------------------------' + '\x0A',
                purchase.note + '\x0A',
                '----------------------------------------' + '\x0A',
                '\x0A', // line break
              ]);
            }

            // cut
            printData = printData.concat([
              '\x1B' + '\x64' + '\x03', // cut
            ]);

            console.log('printData', printData);

            const fileConfig = qz.configs.create({
              file: '/Users/davidhamilton/Downloads/qzoutput.txt',
            });

            if (this.printer) {
              qz.print(config, printData).catch((e: any) => {
                console.error(e);
                // alert(e);
              });
            }
          });
      });
    }
  }

  connectPrinter() {
    // console.log('connecting printer');
    qz.websocket
      .connect()
      .then(() => {
        //  console.log('connected - finding printer');
        this.globals.printer.controllerStatus = 'Connected';
        this.globals.printer.printerStatus = 'Finding Printer';
        qz.printers
          .find()
          .then((data: any) => {
            // console.log('printer list', data);

            for (const printer of data) {
              const star = printer.toLowerCase().includes('star');
              if (star) {
                this.printer = printer;
                this.globals.printer.printerStatus = printer;

                // console.log('this.printer', this.printer);
                // console.log('printerStatus', this.globals.printer.printerStatus);
              } else {
                if (!this.printer) {
                  this.globals.printer.printerStatus = 'No Valid Printer';
                }
              }
            }
          })
          .catch((e: any) => {
            console.error(e);
            // alert(e);
          });
      })
      .catch((e: any) => {
        console.error(e);
        // alert(e);
      });
  }
}
