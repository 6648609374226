import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { CryptoService } from './crypto.service';
import { Globals } from '../_globals';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class WebSocketProvider {

    // accessToken;

    constructor(
        public storage: Storage,
        public cryptoService: CryptoService,
        public globals: Globals,
        public cookieService: CookieService
    ) {
    }

    public connect() {
        const accessToken = this.cryptoService.decryptText(this.cookieService.get(environment.keys.accessToken));

        // tslint:disable-next-line: max-line-length
        // const socket = new SockJS(environment.websocketUrl + 'websocket/purchase/' + this.globals.store.id + '?access_token=' + this.localStorageToken);
        const socket = new SockJS(environment.websocketUrl + 'websocket/purchase?access_token=' + accessToken);
        console.log('connected to websocket');
        const stompClient = Stomp.over(socket);
        stompClient.heartbeat.incoming = 1000;
        stompClient.heartbeat.outgoing = 1000;
        return stompClient;
    }

}
