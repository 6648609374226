import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

    transform(list: any, keyGetter: any): any {

        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        // console.log('map', map);
        // return Array.from(map.values());

        return this.mapToArray(map);

    }

    mapToArray(inputMap) {
        const arr = [];

        inputMap.forEach((value, key) => {
            let inprogress = 0;

            value.forEach((v, k) => {
                if (v.purcahaseStatus === 'IN_PROGRESS') {
                    inprogress++;
                }
            });

            arr.push({
                slot: value[0].workplaceSlot,
                orders: value,
                inProgressCount: inprogress
            });
        });

        return arr;
    }

}
