import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ProductsService {

    constructor(
        private http: HttpClient
    ) { }

    // Get all Stores
    public getAllProductsByStore(storeid) {

        let paginationString = '';
        paginationString += '?size=' + 1000;
        paginationString += '&page=' + 0;

        return this.http.get(environment.apiUrl + 'api/ext-item/by-store/' + storeid + paginationString, { observe: 'response' })
            .pipe(map(this.handleDataWithHeaders))
            .pipe(catchError(this.handleError));
    }

    public updateProduct(product) {
        return this.http.put(environment.apiUrl + 'api/ext-item', product)
            .pipe(map(this.handleData))
            .pipe(catchError(this.handleError));
    }

    // HANDLE DATA
    private handleData(res: HttpResponse<any>) {
        const body = res;
        return body;
    }

    public handleDataWithHeaders(res: HttpResponse<any>) {

        const h = res.headers;
        const b = res.body;
        const data = {
            headers: h,
            body: b
        };
        return data;
    }

    private handleError(error: any) {
        return Observable.throw(
            new HttpErrorResponse(error)
        );
    }

}
