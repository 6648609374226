import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { StarPRNT } from '@ionic-native/star-prnt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './_guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ServerErrorsInterceptor } from './_interceptors/server-errors.interceptor';
import { CryptoService } from './_services/crypto.service';
import { Globals } from './_globals';
import { CookieService } from 'ngx-cookie-service';
import { StoreService } from './_services/store.service';
import { PurchaseService } from './_services/purchase.service';
import { WebSocketProvider } from './_services/websocket';
import { ProductsService } from './_services/products.service';
import { PrintService } from './_services/print.service';

import { GroupByPipe } from './_pipes/group-by.pipe';
import { BuilderGroupPipe } from './_pipes/builder-group.pipe';

@NgModule({
    declarations: [AppComponent, GroupByPipe],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AuthModule
    ],
    providers: [
        AuthGuard,
        StatusBar,
        SplashScreen,
        AuthService,
        CryptoService,
        Globals,
        CookieService,
        StoreService,
        PurchaseService,
        ProductsService,
        WebSocketProvider,
        GroupByPipe, BuilderGroupPipe,
        // StarPRNT,
        PrintService,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorsInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
