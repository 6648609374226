import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'builderGroup'
})
export class BuilderGroupPipe implements PipeTransform {

    transform(list: any): any {

        const map = new Map();

        list.forEach((item) => {
            const key = item.grouping;
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        // console.log('map', map);
        // return Array.from(map.values());

        return this.mapToArray(map);
    }

    mapToArray(inputMap) {
        const arr = [];

        inputMap.forEach((value, key) => {

            // sort items alphabetically
            value.sort((a, b) => a.name.localeCompare(b.name));

            arr.push({
                group: value[0].grouping,
                items: value,
            });
        });

        // sort groups alphabetically
        arr.sort((a, b) => a.group.localeCompare(b.group));

        // console.log('arr', arr);

        return arr;
    }

}
