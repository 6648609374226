import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { ConsoleResolver } from './_resolvers/console.resolver';

const routes: Routes = [
  { path: '', loadChildren: './auth/login/login.module#LoginPageModule'},
  {
    path: 'console',
    resolve: { ConsoleResolver },
    canActivate: [AuthGuard],
    loadChildren: './tabs/tabs.module#TabsPageModule'
  },
  { path: 'products', loadChildren: './products/products.module#ProductsPageModule' },
  { path: 'tab5', loadChildren: './tab5/tab5.module#Tab5PageModule' },
  { path: 'print', loadChildren: './print/print.module#PrintPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [
    ConsoleResolver
  ]
})
export class AppRoutingModule {}
